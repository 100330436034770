.mont-400-11 {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 0.6875rem;
}
.mont-400-12 {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 0.75rem;
}
.mont-400-13 {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 0.8125rem;
}
.mont-400-15 {
  font-family: Montserrat;
  font-weight: 400;
  font-size: 15px;
}
.mont-500-10 {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 0.625rem;
}
.mont-500-11 {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 11px;
}
.mont-500-12 {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 0.75rem;
}
.mont-500-13 {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 13px;
}
.mont-500-14 {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
}
.mont-500-15 {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 15px;
}

.mont-600-13 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.8125rem;
}
.mont-600-14 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
}
.mont-600-15 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 0.9375rem;
}
.mont-600-20 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 1.25rem;
}
.mont-700-15 {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 0.9375rem;
}
.mont-700-24 {
  font-family: Montserrat;
  font-weight: 700;
  font-size: 1.5rem;
}
