@import "antd/dist/antd.less";
@import "./antd-mod.less";

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: #0d3271;
}

::selection {
  color: white;
  background: #0d3271;
}

.dimensions-inherit {
  height: inherit;
  width: inherit;
}

.ant-pagination-item {
  border: 0px;
  border-radius: 100%;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  position: relative;
  bottom: 4px;
}

.ant-pagination.ant-pagination-mini
  .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
  color: #98a2b3 !important;
}

.ant-table-pagination.ant-pagination {
  display: none !important;
}

.ant-pagination-item.ant-pagination-item-active {
  a {
    color: #165abf !important;
  }
}

.custom-message .ant-message-notice-content .ant-message-custom-content {
  display: flex;
  align-items: center;

  .anticon {
    top: 0px;
  }
}

.ant-message-notice-content {
  border-radius: 10px;
}

.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: #98a2b3;
  transition: none;
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: #3979cc;
  border-right-width: 0px;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #98a2b3;
  box-shadow: none !important;
  outline: 0;
}

.ant-input:hover {
  // border-color: #3979cc;
  border-color: #98a2b3;
  border-right-width: 1px;
}

.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > thead
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tbody
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > th,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tfoot
  > tr
  > td,
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-summary
  > table
  > tfoot
  > tr
  > td {
  border-right: unset !important;
}

.ant-table-footer {
  border-radius: 0 0 16px 16px;
  border-top: 0px solid #dde5ed !important;
  border-bottom: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  padding: unset !important;
  width: 100%;
  height: 10%;
}

.ant-tooltip-inner {
  border-radius: 8px;
}

.custom-border {
  .ant-table-footer {
    border-top: 1.5px solid #dde5ed !important;
  }
}

.anticon.ant-input-clear-icon,
.ant-input-clear-icon {
  display: flex;
}

// Fixed to be pushed after Bug raised
.ant-table-thead {
  height: 44px;
}

.ant-table-row {
  height: 58px;
}

.ant-table-column-sorters {
  flex: unset !important;
  justify-content: flex-start;
}

.ant-table-column-title {
  flex: unset !important;
  margin-right: 6px;
}

.custom-table {
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: unset;
  }

  table {
    border-top: unset !important;
    border-radius: 0 0 0 0;
  }

  .ant-table {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0px !important;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0px !important;
  }

  .actions {
    display: none;
  }

  .ant-table-row:hover .actions {
    display: block;
  }

  div.ant-table-body {
    transition: all 0.5s ease-in-out;
  }

  .ant-table-row {
    height: 44px !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 0px;
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid #dde5ed;
  }
}

.table-min-height {
  div.ant-table-body {
    min-height: 250px;
  }
}

.custom-roles-table {
  .ant-table.ant-table-bordered > .ant-table-container {
    border-left: unset;
  }

  table {
    border-top: unset !important;
    border-radius: 0 0 0 0;
  }

  .ant-table {
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 0px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 34px !important;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 0px !important;
  }

  .actions {
    display: none;
  }

  .ant-table-row:hover .actions {
    display: block;
  }

  div.ant-table-body {
    transition: all 0.5s ease-in-out;
  }

  .ant-table-row {
    height: 32px !important;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid #dde5ed;
    font-size: 10px;
    color: #667085;
    background: transparent;
    padding: 0px 20px;
  }
  .ant-table-thead {
    height: 20px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
}

.actions {
  display: none;
}

.actions-row:hover .actions {
  display: block;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #165abf !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #165abf;
  height: 2px;
  width: 8.5px;
  border-radius: 1px;
}

// .ant-checkbox {
//   border-color: #c0cbd7 !important;
// }

// .ant-checkbox:hover {
//   border-color: #165abf !important;
// }

.ant-checkbox-checked::after {
  border-color: #165abf !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #c0cbd7;
}

.custom-range-picker {
  .ant-picker-input > input {
    font-size: 12px;
  }

  .ant-picker-input > input::placeholder {
    font-size: 12px;
  }

  .ant-picker-range-separator {
    padding: 0 1px !important;
  }

  .ant-picker-focused {
    box-shadow: none !important;
    border: none !important;
  }
}

.custom-range-picker-for-phase {
  .ant-picker-input {
    max-width: 75px !important;
  }

  .ant-picker-input > input {
    font-size: 14px;
  }

  .ant-picker-input > input::placeholder {
    font-size: 14px;
  }

  .ant-picker-range-separator {
    padding: 0 5px 0 0 !important;
  }

  .ant-picker-focused {
    box-shadow: none !important;
    border: none !important;
  }

  .ant-picker:hover,
  .ant-picker-focused {
    border: none !important;
  }
}

.custom-range-picker-for-onboarding.ant-picker {
  padding: 4px 0px 4px !important;
}

.custom-range-picker-for-onboarding
  .ant-picker-range.ant-picker-focused
  .ant-picker-active-bar {
  opacity: 0 !important;
}

.custom-range-picker-for-onboarding .ant-picker-active-bar {
  opacity: 0 !important;
}

.custom-range-picker-for-onboarding {
  .ant-picker-input {
    input {
      font-size: 12px !important;
    }
  }
}

.custom-calendar {
  .ant-picker-panel {
    background-color: #f9fbfe !important;
  }

  .ant-picker-calendar .ant-picker-panel .ant-picker-body {
    padding: 0px !important;
  }

  .ant-picker-calendar .ant-picker-panel {
    background-color: #f9fbfe !important;
    border-top: 0px !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    border-radius: 50%;
    color: #f9fbfe;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border: 0px;
  }

  .ant-picker-cell {
    font-size: 12px;
    color: #98a2b3;
    font-weight: 500;
  }

  .ant-picker-cell-in-view {
    font-size: 12px;
    color: #292927;
    font-weight: 500;
  }

  .ant-picker-content th {
    font-size: 12px;
    color: #292927;
    font-weight: 500;
    height: 31px;
  }
}

.ant-select-item-option-grouped {
  padding-left: 12px;
}

.ant-drawer-footer {
  flex-shrink: 0;
  padding: 0px;
  border-top: none;
}

.goal-new-scope {
  background-color: #fef6fb !important;
}

// Ant Collapse Panel
.custom-collapse-panel {
  .ant-collapse-header {
    .ant-collapse-expand-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 17px;
      width: 25px;
    }

    .ant-collapse-header-text {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 11px;
      color: #165abf;
    }
  }
}

// Ellipsis two lines: https://stackoverflow.com/a/30940678
.ellipsis-two-lines {
  line-height: 1.125rem;
  height: 2.25rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Flexible grid
.flexible-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(173px, 173px));
  grid-gap: 16px;
}

.custom-collapse {
  .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    right: 0px !important;
  }

  .ant-collapse-item > .ant-collapse-header {
    padding-left: 0px;
  }

  .ant-collapse-content-box {
    padding: 0px !important;
  }
}

.ant-mentions > textarea::placeholder {
  font-size: 12px;
  font-weight: 400;
  color: #667085;
}

.ant-mentions > textarea {
  background-color: #f9fbfe;
}

.ant-mentions:hover {
  border-color: transparent;
  border-right-width: 0px;
}

.ant-mentions-focused {
  box-shadow: none;
}

.comments-textarea {
  width: inherit;
}

.comments-textarea__control {
  // margin-top: 10px;
  // margin-bottom: 0px;
  // min-width: 90%;
  height: 100%;
}

.comments-textarea__input {
  background-color: #fff;
  border-radius: 3px;
  transition: 0.3s ease-in-out;
  padding: 16px 17px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #101828;
  font-weight: 400;
  border: none;
  top: 2.5px !important;

  &:focus,
  &:active {
    outline: none;
    border: none;
  }

  &::placeholder {
    color: #667085;
    font-size: 12px;
    font-weight: 400;
  }
}

.comments-textarea__highlighter {
  padding: 16px 17px;
  min-height: 52px;
}

.comments-textarea__suggestions__list {
  background: #f9fbfe;
  /* Gray (Maintenance)/100 */

  // border: 1px solid #edf3f9;
  /* Shadow/lg */

  // box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
  //   0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  max-height: 284px;
  width: 320px;
  overflow-y: scroll;
}

.comments-textarea__suggestions__item {
  padding: 0px 14px;
  padding-bottom: 10px;

  &:hover {
    background-color: #eff8ff;
  }

  &:active {
    background-color: #eff8ff;
  }

  &[aria-selected="true"] {
    background-color: #eff8ff;
  }

  &:first-child {
    padding-top: 15px;
  }

  &:last-child {
    padding-bottom: 15px;
  }

  &:not(:first-child) {
    padding-top: 10px;
  }
}

.comments-textarea__suggestions {
  background: #f9fbfe;
  border: 1px solid #edf3f9;
  border-radius: 8px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.comments-textarea__suggestions__item__focused {
  background-color: #daf4fa;
}

.custom-upload-hide.ant-upload-picture-card-wrapper {
  display: none;
}

.custom-upload.ant-upload-picture-card-wrapper {
  padding: 8px 16px;
}

.ant-upload-list-item {
  border-radius: 8px !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  height: 56px;
  width: 56px;
}

.ant-upload-list-picture-card-container {
  height: 56px;
  width: 56px;
}

.ant-upload.ant-upload-select-picture-card {
  height: 56px;
  width: 56px;
  border-radius: 8px !important;
}

// aside.emoji-picker-react {
//   position: absolute !important;
//   bottom: 44px !important;
//   right: 0px !important;
// }

// aside.emoji-picker-react .emoji-panel-open {
//   position: absolute !important;
//   bottom: 44px !important;
//   right: 0px !important;
// }

.conversation-drawer {
  .ant-drawer-body {
    padding: 0px;
  }
}

.image-crop-modal {
  .ant-modal-content {
    border-radius: 12px;

    .ant-modal-header {
      border-radius: 12px 12px 0 0;

      .ant-modal-title {
        font-family: Montserrat, sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: rgb(16 24 40);
      }
    }

    .ant-modal-footer {
      button {
        border-radius: 6px;
        font-weight: 500;
      }

      .ant-btn-primary {
        background: #165abf;
      }
    }
  }
}

// .ant-select-selector {
//   height: 100% !important;
// }

// .ant-select-selection-item {
//   display: flex;
//   align-items: center;
//   // margin-top: -2px;
// }

.ant-drawer-body {
  background-color: #f9fbfe !important;
}

.suggestion-thread-container {
  background-color: #f9fbfe;
}

.IDGvz {
  border-radius: 8px !important;
  border: none;
  border-width: 0px;
}

.ant-breadcrumb > ol > li:last-child {
  span {
    font-weight: 500;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

// Custom Progress bar 4px
.progress-bar-tiny {
  .ant-progress-outer {
    .ant-progress-inner {
      height: 4px !important;

      .ant-progress-bg {
        height: 4px !important;
      }
    }
  }
}

.progress-upload {
  .ant-progress-inner {
    background-color: transparent !important;
  }
}

.progress-bar-tiny {
  .ant-progress-outer {
    .ant-progress-inner {
      height: 4px !important;

      .ant-progress-bg {
        height: 4px !important;
      }
    }
  }
}

.custom-drawer-shared-files {
  .ant-drawer-body {
    padding: 0px !important;
  }
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// Custom Todo
.custom-todo-item {
  &:hover {
    & > .custom-todo-delete {
      display: block !important;
    }
    & > .custom-todo-flex {
      display: flex !important;
    }
  }
}

// Custom Unput
.shadow-input-focus {
  box-shadow: 0px 0px 0 4px rgba(125, 199, 255, 0.4);
}

.shadow-input-error {
  box-shadow: 0px 0px 0 4px rgba(253, 162, 155, 0.3);
}

// Ant progress
.ant-progress-status-success .ant-progress-bg {
  background-color: #165abf;
}

.ant-progress-inner {
  background-color: #edf3f9 !important;
}

// Progress Spinner SVG
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spin-loader {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

// Custom OTP Input
.custom-otp-input {
  width: 44px !important;
  text-align: center;
  height: 44px;
  border: 1px solid rgb(192, 203, 215);
  border-radius: 12px;
  margin-right: 19px;

  &:focus {
    box-shadow: 0px 0px 0 4px rgba(125, 199, 255, 0.4) !important;
    outline: none !important;
  }

  &:focus-visible {
    box-shadow: 0px 0px 0 4px rgba(125, 199, 255, 0.4) !important;
    outline: none !important;
  }
}

// onboarding image
.img-wrapper {
  height: 100vh;
  width: 100%;
  //position: relative;
  overflow-y: auto;
}

.img-wrapper > img {
  position: relative;
}

// Ant Autocomplete

// .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-auto-complete .ant-select-selector{
//   color: rgba(0, 0, 0, 0.85) !important;
// }

.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba(0, 0, 0, 0.85) !important;
}

.ant-input[disabled] {
  background-color: #f0f5fa !important;
}

.ant-notification-notice {
  padding: 16px 10px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-left: 38px !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 38px;
}

// custom-popover-open
.custom-popover-open {
  transform: rotate(180deg) !important;
}

//
.custom-address-input {
  width: 100% !important;
}
// Ant switch checked
.ant-switch .ant-switch-checked {
  background-color: #165abf !important;
  background: #165abf !important;
}

.list-table .ant-table-thead > tr > th {
  background-color: white !important;
}

.list-table .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 12px !important;
}

.list-table .ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 12px !important;
}

.list-table .ant-table {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0px !important;
}

// .list-table
//   .ant-table-cell
//   .ant-table-column-sort
//   .ant-table-column-has-sorters {
//   background-color: white !important;
// }

.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  height: 10.125rem;
}

.custom-table div.ant-table-body {
  transition: all 0.5s ease-in-out;
}

.custom-address-input-ob {
  .ant-select-selector {
    padding-left: 0px !important;
    .ant-select-selection-search {
      left: 0px !important;
    }
  }

  .ant-select-selector {
    left: 0px !important;
    right: 0px !important;
  }
}

.ant-picker-ok {
  button {
    border-radius: 6px;
    font-weight: 500;
  }

  .ant-btn-primary {
    background: #165abf;
    color: white;
  }
}

.ant-carousel .slick-prev,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-prev:focus {
  font-size: inherit;
  left: 10px;
  z-index: 2;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  right: 10px;
  z-index: 2;
}

.ant-carousel {
  .slick-next {
    &::before {
      content: "";
    }
  }
  .slick-prev {
    &::before {
      content: "";
    }
  }
}

.ant-checkbox-inner::after {
  width: 5.5px;
  height: 9.5px;
  top: 46%;
}
// DATE PICKER STYLES

.ant-picker-panel-container {
  background: #fff;
  /* Shadow/xl */
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
}

.ant-picker-month-btn .ant-picker-year-btn {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center;
  color: #344054 !important;
}

.ant-picker-content th {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #344054 !important;
}

.ant-picker-date-panel .ant-picker-content th {
  height: 36px;
}

.ant-picker-content th,
.ant-picker-content td {
  height: 36px !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
  .ant-picker-cell-inner {
  border-radius: 35px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
  .ant-picker-cell-inner {
  border-radius: 35px;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  border-radius: 35px;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 35px;
}

// .ant-picker-cell-selected{
//   background-color: #165abf;
//   border-radius: 35px;

// }
// .progress-line {
//   position: fixed;
//   left: 0;
//   top: 0;
//   bottom: 0;
//   width: 4px;
//   background: black;
// }
// .progress-line .step {
//   position: absolute;
//   width: 100%;
//   height: 33%;
//   display: flex;
//   align-items: center;
// }
// .progress-line .step .number {
//   font-size: 12px;
//   color: white;
//   margin-left: 8px;
// }
// .progress-line .step .box {
//   position: absolute;
//   top: -8px;
//   left: 0;
//   width: 16px;
//   height: 16px;
//   background: black;
//   border-radius: 8px;
// }
// .progress-line .step.top {
//   top: 0;
// }
// .progress-line .step.middle {
//   top: 33%;
// }
// .progress-line .step.bottom {
//   bottom: 0;
// }
// .given-element {
//   position: relative;
// }
// .given-element::before {
//   content: "";
//   position: absolute;
//   top: -16px;
//   left: 0;
//   right: 0;
//   height: 8px;
//   background: #555;
// }

// .avatars {
//   display: inline-flex;
//   flex-direction: row-reverse;
// }

// .avatar {
//   position: relative;
//   border: 4px solid #fff;
//   border-radius: 50%;
//   overflow: hidden;
//   //width: 100px;
// }

// .avatar:not(:last-child) {
//   margin-left: -60px;
// }

// .avatar img {
//   width: 100%;
//   display: block;
// }

.avatar-group {
  display: flex;
  justify-content: center;
}

.avatar {
  width: 34px;
  height: 34px;
  overflow: hidden;
  border-radius: 50%;
  box-sizing: content-box;
  transition: transform ease 300ms;
}

.avatar-group .avatar:not(:first-child) {
  margin-left: -8px;
}

.avatar-group .avatar {
  border: 1px solid #fff;
}

.avatar:hover {
  transform: translate(0, -5px);
}

@primary-color: #165ABF;