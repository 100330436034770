@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./Modules/Vault/file-preview.css";

* {
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
  height: 0;
}

/* .App {
  height: 100vh;
} */

p {
  margin-bottom: 0px !important;
}

.custom-menu-dropdown.ant-dropdown-menu {
  border-radius: 8px !important;
  margin: 0px !important;
  padding: 0px !important;
  background-color: #f9fbfe !important;
}

a:hover {
  color: #165abf;
}

.overview-tab .ant-tabs-tab {
  padding-bottom: 4px !important;
  padding-top: 0px !important;
}

.mon-500-10 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 10px;
}

.mon-500-12 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 12px;
}
.mon-500-14 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 14px;
}
.mon-500-16 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.mon-500-18 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 18px;
}

.mon-500-20 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.mon-600-20 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 20px;
}
.mon-600-30 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 30px;
}
.mon-600-12 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 12px;
}
.mon-600-14 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.mon-600-18 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 18px;
}

.mon-600-24 {
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 24px;
}
.mon-500-20 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
.mon-500-30 {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 38px;
}

.mon-400-12 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 12px;
}
.mon-400-10 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 10px;
}

.mon-400-14 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 14px;
}

.mon-400-16 {
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.mon-400-24 {
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
}

*::-webkit-scrollbar {
  width: 0px;
  height: 4px;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background: #dde5ed;
  border-radius: 10px;
  min-height: 4px !important;
}
.font-nunito-sans {
  font-family: "Nunito Sans", sans-serif;
}

.project-progress-container {
  box-shadow: 2px 3px 6px rgba(16, 24, 40, 0.06);
}

.custom-drag-drop {
  background-color: #f9fbfe !important;
  border-radius: 12px !important;
}

/* li[aria-selected="true"]{
  background-color: #F0F5FA !important;
} */

.ant-dropdown-menu > .ant-dropdown-menu-item.ant-dropdown-menu-item-active {
  background-color: #F0F5FA !important;
}

.ant-dropdown-menu > li:first-child {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.ant-dropdown-menu > li:last-child {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.ant-dropdown-menu > li:not(:first-child):not(:last-child) {
  border-radius: 0 !important;
}

.radial-blue-card {
  background: radial-gradient(
    100% 80% at 2.5% 0%,
    #2e90fa 0%,
    #1761cb 98.16%,
    #1359c4 98.42%
  );
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.radial-blue-card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    100% 80% at 2.5% 0%,
    #2e90fa 0%,
    #1761cb 98.16%,
    #1359c4 98.42%
  );
  transition: all 0.5s ease-out;
}

.radial-blue-card:hover:before {
  transform: scale(5);
}

.radial-grey-card {
  background: radial-gradient(
    100% 80% at 2.5% 0%,
    #f9fbfe 0%,
    #eaf0f7 98.16%,
    #e2eaf2 98.42%
  );
  overflow: hidden;
  position: relative;
  z-index: 0;
  /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
}

.radial-grey-card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(
    100% 80% at 2.5% 0%,
    #f9fbfe 0%,
    #eaf0f7 98.16%,
    #e2eaf2 98.42%
  );
  transition: all 0.5s ease-out;
}

.radial-grey-card:hover:before {
  transform: scale(5);
}

@keyframes text-show {
  from {
    opacity: 0;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    margin-bottom: 1.5rem;
  }
}
@keyframes text-show-project {
  from {
    opacity: 0;
    margin-bottom: 0;
  }
  to {
    opacity: 1;
    margin-bottom: 2rem;
  }
}

.text-show-animation {
  margin-bottom: 1.5rem;
  opacity: 1;
  animation-name: text-show;
  animation-duration: 2s;
}

.text-show-animation-project {
  margin-bottom: 2rem;
  opacity: 1;
  animation-name: text-show-project;
  animation-duration: 1s;
}

.hexagon-list:nth-child(even) {
  padding-left: 48px;
}
.hexagon-list:first-child {
  right: 55px;
}
.hexagon {
  position: relative;
  float: left;
  width: 94px;
  height: 104px;
  padding: 2px;
  border-radius: 10px;
  box-sizing: border-box;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  cursor: pointer;
}

.unset-wnh {
  width: unset !important;
  height: unset !important;
}
.hex-two-row:nth-child(even) {
  padding-left: 22px !important;
}

.placeholder-hexagon {
  visibility: hidden;
}

.transform-new {
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 2s ease;
  will-change: transform-new;
}

.start-animate-to-top {
  bottom: 0rem;
}
.animate-to-top {
  bottom: 12rem;
}
/* .hexagon:hover {
  background-color: #149d8b;
}
.hexagon:hover:before {
  border-bottom-color: #149d8b;
}
.hexagon:hover:after {
  border-top-color: #149d8b;
} */
/* .hexagon:before,
.hexagon:after {
  position: absolute;
  content: "";
  border-left: 47px solid transparent;
  border-right: 47px solid transparent;
  left: 0;
  right: 0;
  border-radius: 1px;
} */

/* .hexagon:before {
  margin-bottom: -1px;
  transition: border-bottom-color 0.5s ease;
  bottom: 100%;
  border-radius: 3px;
}

.hexagon:after {
  margin-top: -1px;
  transition: border-top-color 0.5s ease;
  top: 100%;
  border-radius: 3px;
} */

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}


.jumping-element {
  position: absolute;
  bottom: 50px;
  left: 25%;
  transform: translateX(-75%);
  cursor: pointer;
  animation: jump 2s ease-in-out infinite;
}

@keyframes jump {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
}

.jumping-element.hover {
  animation-play-state: paused;
}

/**
 * ==============================================
 * Dot Plus
 * ==============================================
 */

/* .dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dot-pulse::before, .dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}
.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px;
  }
} */

/**
 * ==============================================
 * Dot Collision
 * ==============================================
 */
 .dot-collision {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #98A2B3;
  color: #98A2B3;
}
.dot-collision::before, .dot-collision::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-collision::before {
  left: -7px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #98A2B3;
  color: #98A2B3;
  animation: dot-collision-before 2s infinite ease-in;
}
.dot-collision::after {
  left: 7px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #98A2B3;
  color: #98A2B3;
  animation: dot-collision-after 2s infinite ease-in;
  animation-delay: 1s;
}

@keyframes dot-collision-before {
  0%, 50%, 75%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-15px);
  }
}
@keyframes dot-collision-after {
  0%, 50%, 75%, 100% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(15px);
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 10px;
  /* overflow-x: scroll; */
  /* overflow: visible; */
  /* width: 500px; */
  grid-auto-flow: column;
  grid-auto-columns: minmax(150px,1fr);

}

.grid-item {
  background-color: lightgray;
  border: 1px solid black;
  text-align: center;
}

 .kanban-col > div:last-child > div{
  margin-bottom: 16px;
}
/*
.kanban-col > div:first-child > div{
  margin-top: 0px !important;
} */

.ant-select-tree-switcher {
  line-height: 28px;
}

.ant-select-tree .ant-select-tree-node-content-wrapper{
  line-height: 2.1875rem;
}